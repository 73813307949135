/* eslint-disable @next/next/no-img-element */
import NextLink from 'next/link';
import { useEffect, useState } from 'react';
import {
  Heading,
  Icon,
  Link,
  Text,
  trackEvent,
} from '@requity-homes/component-library';
import { getCookie } from 'cookies-next';
import { getCustomerPortalBaseUrl } from '../../utils';
import { cookiesIdToken } from '@requity-homes/utils';

const listingsHomePage =
  process.env.NEXT_PUBLIC_STAGE === 'local-dev'
    ? '/'
    : 'https://www.requityhomes.com/listings';

const customerPortalBaseUrl = getCustomerPortalBaseUrl(
  process.env.NEXT_PUBLIC_STAGE,
);

export const Header = ({ city }: { city?: string }) => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showHomesList, setShowHomesList] = useState(false);
  const [showRTOGuides, setShowRTOGuides] = useState(false);
  const isLoggedIn = getCookie(cookiesIdToken);

  const handleOpenNewTab = (event) => {
    event.preventDefault();
    trackEvent('listing-sign-up-click', { city });
    window.open(`${customerPortalBaseUrl}/auth/sign-up`, '_blank');
  };

  const navigateToMyListings = (event) => {
    event.preventDefault();

    const myListingsPage = `${customerPortalBaseUrl}/v2/home-listings`;

    window.open(myListingsPage, '_blank');
  };

  useEffect(() => {
    if (!showMobileMenu) {
      setShowHomesList(false);
      setShowRTOGuides(false);
    }
  }, [showMobileMenu]);

  const menuItems = (
    <>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.requityhomes.com/how-it-works"
        className="font-bold hover:text-coral-medium"
      >
        How It Works
      </a>
      <a
        href={`${listingsHomePage}`}
        className="font-bold hover:text-coral-medium"
        onClick={(event) => {
          event.preventDefault();
          setShowHomesList((prev) => !prev);
          setShowRTOGuides(false);
        }}
      >
        Browse Homes
        {showHomesList ? (
          <Icon glyph="chevronUp" className="ml-1 h-4 w-4" />
        ) : (
          <Icon glyph="chevronDown" className="ml-1 h-4 w-4" />
        )}
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.requityhomes.com/calculators"
        className="font-bold hover:text-coral-medium"
      >
        Calculators
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.requityhomes.com/success-stories"
        className="font-bold hover:text-coral-medium"
      >
        Success Stories
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.requityhomes.com/partners"
        className="font-bold hover:text-coral-medium"
      >
        Partners
      </a>
      <a
        href={`${listingsHomePage}`}
        className="font-bold hover:text-coral-medium"
        onClick={(event) => {
          event.preventDefault();
          setShowRTOGuides((prev) => !prev);
          setShowHomesList(false);
        }}
      >
        Rent-to-Own Guides
        {showRTOGuides ? (
          <Icon glyph="chevronUp" className="ml-1 h-4 w-4" />
        ) : (
          <Icon glyph="chevronDown" className="ml-1 h-4 w-4" />
        )}
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.requityhomes.com/faq"
        className="font-bold hover:text-coral-medium"
      >
        FAQ
      </a>
    </>
  );

  return (
    <div className="bg-coral-light md:py-8 px-4 md:p-8 md:w-full z-30 h-24 flex items-center justify-between relative">
      <div className="flex gap-4 justify-between w-full">
        <div className="flex items-center justify-center">
          <a
            href="https://www.requityhomes.com/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="https://assets-global.website-files.com/61e19be21795b3fa062d021f/624dd69a94af1b5f89ef13f5_requity-logo.svg"
              alt="Requity Homes logo"
              className="w-28 md:w-36"
            />
          </a>
        </div>
        {showMobileMenu ? (
          <MobileMenu menuItems={menuItems} />
        ) : (
          <div className="hidden md:flex gap-12 items-center justify-center">
            {menuItems}
          </div>
        )}
        <div className="flex items-center justify-center gap-2 md:gap-4">
          {!isLoggedIn && (
            <NextLink passHref href="https://www.requityhomes.com/log-in">
              <a
                target="_blank"
                rel="noreferrer"
                className="text-coral-medium hover:text-white whitespace-nowrap hidden md:block"
              >
                <strong>Log In</strong>
              </a>
            </NextLink>
          )}
          {isLoggedIn ? (
            <div>
              <a
                className="py-2.5 md:py-3 px-4 md:px-8 rounded-3xl cursor-pointer text-white hover:text-coral-medium bg-coral-medium hover:bg-white hover:shadow-md-dark md:flex md:text-center font-bold whitespace-nowrap"
                onClick={(event) => navigateToMyListings(event)}
              >
                My Account
              </a>
              <button
                className="md:hidden h-7 w-7 ml-2"
                onClick={() => setShowMobileMenu(!showMobileMenu)}
              >
                {showMobileMenu ? (
                  <Icon glyph="close" className="md:hidden h-full w-full" />
                ) : (
                  <Icon
                    glyph="hamburgerMenu"
                    className="md:hidden h-full w-full"
                  />
                )}
              </button>
            </div>
          ) : (
            <div>
              <a
                className="py-2.5 md:py-3 px-4 md:px-8 rounded-3xl cursor-pointer text-white hover:text-coral-medium bg-coral-medium hover:bg-white hover:shadow-md-dark md:flex md:text-center font-bold whitespace-nowrap"
                onClick={(event) => handleOpenNewTab(event)}
              >
                Sign Up
              </a>
              <button
                className="md:hidden h-7 w-7 ml-2"
                onClick={() => setShowMobileMenu(!showMobileMenu)}
              >
                {showMobileMenu ? (
                  <Icon glyph="close" className="md:hidden h-full w-full" />
                ) : (
                  <Icon
                    glyph="hamburgerMenu"
                    className="md:hidden h-full w-full"
                  />
                )}
              </button>
            </div>
          )}
        </div>
      </div>
      {showHomesList && (
        <div
          className="absolute bg-coral-light left-0 right-0 top-24 p-8 z-50 text-centet"
          onClick={() => setShowHomesList(false)}
        >
          <div className="py-8 px-12 rounded-2xl bg-white text-navy-dark">
            <Heading
              element="h3"
              level="h3"
              className="text-center md:text-left"
            >
              Browse Homes
            </Heading>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-6 md:gap-4 mt-12">
              <div className="flex flex-col text-center md:text-left">
                <Text fontWeight="bold" useCase="paragraph">
                  Alberta
                </Text>
                <div className="flex flex-col gap-2 mt-6">
                  <Link
                    className="text-navy-dark hover:text-navy-dark"
                    href="https://www.requityhomes.com/listings/ab/airdrie/rent-to-own"
                  >
                    Rent-to-Own Homes in Airdrie
                  </Link>
                  <Link
                    className="text-navy-dark hover:text-navy-dark"
                    href="https://www.requityhomes.com/listings/ab/beaumont/rent-to-own"
                  >
                    Rent-to-Own Homes in Beaumont
                  </Link>
                  <Link
                    className="text-navy-dark hover:text-navy-dark"
                    href="https://www.requityhomes.com/listings/ab/edmonton/rent-to-own"
                  >
                    Rent-to-Own Homes in Edmonton
                  </Link>
                  <Link
                    className="text-navy-dark hover:text-navy-dark"
                    href="https://www.requityhomes.com/listings/ab/grande-prairie/rent-to-own"
                  >
                    Rent-to-Own Homes in Grande Prairie
                  </Link>
                  <Link
                    className="text-navy-dark hover:text-navy-dark"
                    href="https://www.requityhomes.com/listings/ab/leduc/rent-to-own"
                  >
                    Rent-to-Own Homes in Leduc
                  </Link>
                  <Link
                    className="text-navy-dark hover:text-navy-dark"
                    href="https://www.requityhomes.com/listings/ab/lethbridge/rent-to-own"
                  >
                    Rent-to-Own Homes in Lethbridge
                  </Link>
                  <Link
                    className="text-navy-dark hover:text-navy-dark"
                    href="https://www.requityhomes.com/listings/ab/red-deer/rent-to-own"
                  >
                    Rent-to-Own Homes in Red Deer
                  </Link>
                  <Link
                    className="text-navy-dark hover:text-navy-dark"
                    href="https://www.requityhomes.com/listings/ab/sherwood-park/rent-to-own"
                  >
                    Rent-to-Own Homes in Sherwood Park
                  </Link>
                  <Link
                    className="text-navy-dark hover:text-navy-dark"
                    href="https://www.requityhomes.com/listings/ab/spruce-grove/rent-to-own"
                  >
                    Rent-to-Own Homes in Spruce Grove
                  </Link>
                  <Link
                    className="text-navy-dark hover:text-navy-dark"
                    href="https://www.requityhomes.com/listings/ab/st.-albert/rent-to-own"
                  >
                    Rent-to-Own Homes in St. Albert
                  </Link>
                  <Link
                    className="text-navy-dark hover:text-navy-dark"
                    href="https://www.requityhomes.com/listings/ab/stony-plain/rent-to-own"
                  >
                    Rent-to-Own Homes in Stony Plain
                  </Link>
                </div>
              </div>
              <div className="flex flex-col text-center md:text-left">
                <Text fontWeight="bold" useCase="paragraph">
                  Manitoba
                </Text>
                <div className="flex flex-col gap-2 mt-6">
                  <Link
                    className="text-navy-dark hover:text-navy-dark"
                    href="https://www.realtor.ca/mb/winnipeg/real-estate"
                  >
                    Rent-to-Own Homes in Winnipeg
                  </Link>
                </div>

                <div className="flex flex-col text-center md:text-left">
                  <Text
                    fontWeight="bold"
                    useCase="paragraph"
                    className="mt-6 mb-4"
                  >
                    Ontario
                  </Text>
                  <div className="flex flex-col gap-2">
                    <Link
                      className="text-navy-dark hover:text-navy-dark"
                      href="https://www.requityhomes.com/listings/on/north-bay/rent-to-own"
                    >
                      Rent-to-Own Homes in North Bay
                    </Link>
                    <Link
                      className="text-navy-dark hover:text-navy-dark"
                      href="https://www.requityhomes.com/listings/on/sault-ste.-marie/rent-to-own"
                    >
                      Rent-to-Own Homes in Sault Ste. Marie
                    </Link>
                    <Link
                      className="text-navy-dark hover:text-navy-dark"
                      href="https://www.requityhomes.com/listings/on/sudbury/rent-to-own"
                    >
                      Rent-to-Own Homes in Sudbury
                    </Link>
                    <Link
                      className="text-navy-dark hover:text-navy-dark"
                      href="https://www.requityhomes.com/listings/on/thunder-bay/rent-to-own"
                    >
                      Rent-to-Own Homes in Thunder Bay
                    </Link>
                  </div>
                </div>
              </div>
              <div className="flex flex-col text-center md:text-left">
                <Text fontWeight="bold" useCase="paragraph">
                  Saskatchewan
                </Text>
                <div className="flex flex-col gap-2 mt-6">
                  <Link
                    className="text-navy-dark hover:text-navy-dark"
                    href="https://www.requityhomes.com/listings/sk/martensville/rent-to-own"
                  >
                    Rent-to-Own Homes in Martensville
                  </Link>
                  <Link
                    className="text-navy-dark hover:text-navy-dark"
                    href="https://www.requityhomes.com/listings/sk/moose-jaw/rent-to-own"
                  >
                    Rent-to-Own Homes in Moose Jaw
                  </Link>
                  <Link
                    className="text-navy-dark hover:text-navy-dark"
                    href="https://www.requityhomes.com/listings/sk/prince-albert/rent-to-own"
                  >
                    Rent-to-Own Homes in Prince Albert
                  </Link>
                  <Link
                    className="text-navy-dark hover:text-navy-dark"
                    href="https://www.requityhomes.com/listings/sk/regina/rent-to-own"
                  >
                    Rent-to-Own Homes in Regina
                  </Link>
                  <Link
                    className="text-navy-dark hover:text-navy-dark"
                    href="https://www.requityhomes.com/listings/sk/warman/rent-to-own"
                  >
                    Rent-to-Own Homes in Warman
                  </Link>
                </div>
              </div>
              <div className="flex flex-col text-center md:text-left">
                <div className="bg-coral-light p-8 rounded-2xl text-center flex flex-col gap-5">
                  <Text useCase="paragraph" fontWeight="bold">
                    Find your dream home today
                  </Text>
                  <Text>
                    Start with just 2% down and get pre-qualified in minutes -
                    no impact on your credit score.
                  </Text>
                  <Link
                    target="_blank"
                    href="https://portal.requityhomes.com/auth/sign-up"
                    className="bg-coral-medium hover:bg-white text-white hover:text-coral-medium py-4 rounded-lg font-bold no-underline"
                  >
                    Pre-Qualify Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showRTOGuides && (
        <div
          className="absolute bg-coral-light left-0 right-0 top-24 p-8 z-50 text-centet"
          onClick={() => setShowRTOGuides(false)}
        >
          <div className="py-8 px-12 rounded-2xl bg-white text-navy-dark">
            <Heading
              element="h3"
              level="h3"
              className="text-center md:text-left"
            >
              Rent-to-own Guides
            </Heading>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-6 md:gap-4 mt-12">
              <div className="flex flex-col text-center md:text-left">
                <Text fontWeight="bold" useCase="paragraph">
                  Getting Started
                </Text>
                <div className="flex flex-col gap-2 mt-6">
                  <Link
                    target="_blank"
                    className="text-navy-dark hover:text-navy-dark"
                    href="https://www.requityhomes.com/post/self-employed-mortgages"
                  >
                    How to Buy a Home When Self-Employed
                  </Link>
                  <Link
                    target="_blank"
                    className="text-navy-dark hover:text-navy-dark"
                    href="https://www.requityhomes.com/post/buying-homes-for-newcomers-in-canada-what-you-need-to-know"
                  >
                    Buying Homes for Newcomers in Canada: What to Know
                  </Link>
                  <Link
                    target="_blank"
                    className="text-navy-dark hover:text-navy-dark"
                    href="https://www.requityhomes.com/post/rent-to-own-in-canada"
                  >
                    What is Rent-to-Own
                  </Link>
                  <Link
                    target="_blank"
                    className="text-navy-dark hover:text-navy-dark"
                    href="https://www.requityhomes.com/post/rent-to-own-agreements"
                  >
                    Rent-to-Own Agreements
                  </Link>
                  <Link
                    target="_blank"
                    className="text-navy-dark hover:text-navy-dark"
                    href="https://www.requityhomes.com/post/rent-to-own-myths-and-misconceptions-debunked"
                  >
                    Rent-to-Own Myths & Misconceptions
                  </Link>
                  <Link
                    target="_blank"
                    className="text-navy-dark hover:text-navy-dark"
                    href="https://www.requityhomes.com/post/rent-to-own-vs-traditional-mortgage"
                  >
                    Rent-to-Own vs Traditional Mortgages
                  </Link>
                  <Link
                    target="_blank"
                    className="text-navy-dark hover:text-navy-dark"
                    href="https://www.requityhomes.com/post/does-rent-to-own-build-credit"
                  >
                    Does Rent-to-Own Build Credit
                  </Link>
                  <Link
                    target="_blank"
                    className="text-navy-dark hover:text-navy-dark font-bold"
                    href="https://www.requityhomes.com/blog"
                  >
                    View All Articles
                  </Link>
                </div>
              </div>
              <div className="flex flex-col text-center md:text-left">
                <Text fontWeight="bold" useCase="paragraph">
                  Rent-to-Own in Alberta
                </Text>
                <div className="flex flex-col gap-2 mt-6">
                  <Link
                    target="_blank"
                    className="text-navy-dark hover:text-navy-dark"
                    href="https://www.requityhomes.com/post/rent-to-own-a-home-in-airdrie-alberta"
                  >
                    Rent-to-Own in Airdrie
                  </Link>
                  <Link
                    target="_blank"
                    className="text-navy-dark hover:text-navy-dark"
                    href="https://www.requityhomes.com/post/rent-to-own-a-home-in-beaumont-alberta"
                  >
                    Rent-to-Own in Beaumont
                  </Link>
                  <Link
                    target="_blank"
                    className="text-navy-dark hover:text-navy-dark"
                    href="https://www.requityhomes.com/post/rent-to-own-a-home-in-calgary-alberta"
                  >
                    Rent-to-Own in Calgary
                  </Link>
                  <Link
                    target="_blank"
                    className="text-navy-dark hover:text-navy-dark"
                    href="https://www.requityhomes.com/post/rent-to-own-a-home-in-edmonton-alberta"
                  >
                    Rent-to-Own in Edmonton
                  </Link>
                  <Link
                    target="_blank"
                    className="text-navy-dark hover:text-navy-dark"
                    href="https://www.requityhomes.com/post/rent-to-own-a-home-in-grande-prairie-alberta"
                  >
                    Rent-to-Own in Grande Prairie
                  </Link>
                  <Link
                    target="_blank"
                    className="text-navy-dark hover:text-navy-dark"
                    href="https://www.requityhomes.com/post/rent-to-own-a-home-in-leduc-alberta"
                  >
                    Rent-to-Own in Leduc
                  </Link>
                  <Link
                    target="_blank"
                    className="text-navy-dark hover:text-navy-dark"
                    href="https://www.requityhomes.com/post/rent-to-own-a-home-in-lethbridge-alberta"
                  >
                    Rent-to-Own in Lethbridge
                  </Link>
                  <Link
                    target="_blank"
                    className="text-navy-dark hover:text-navy-dark"
                    href="https://www.requityhomes.com/post/rent-to-own-a-home-in-lloydminster-alberta"
                  >
                    Rent-to-Own in Lloydminster
                  </Link>
                  <Link
                    target="_blank"
                    className="text-navy-dark hover:text-navy-dark"
                    href="https://www.requityhomes.com/post/rent-to-own-a-home-in-red-deer-alberta"
                  >
                    Rent-to-Own in Red Deer
                  </Link>
                  <Link
                    target="_blank"
                    className="text-navy-dark hover:text-navy-dark"
                    href="https://www.requityhomes.com/post/rent-to-own-a-home-in-sherwood-park-alberta"
                  >
                    Rent-to-Own in Sherwood Park
                  </Link>
                  <Link
                    target="_blank"
                    className="text-navy-dark hover:text-navy-dark"
                    href="https://www.requityhomes.com/post/rent-to-own-a-home-in-spruce-grove-alberta"
                  >
                    Rent-to-Own in Spruce Grove
                  </Link>
                  <Link
                    target="_blank"
                    className="text-navy-dark hover:text-navy-dark"
                    href="https://www.requityhomes.com/post/rent-to-own-a-home-in-st-albert-alberta"
                  >
                    Rent-to-Own in St. Albert
                  </Link>
                  <Link
                    target="_blank"
                    className="text-navy-dark hover:text-navy-dark"
                    href="https://www.requityhomes.com/post/rent-to-own-a-home-in-stony-plain-alberta"
                  >
                    Rent-to-Own in Stony Plain
                  </Link>
                </div>
              </div>
              <div className="flex flex-col text-center md:text-left">
                <Text fontWeight="bold" useCase="paragraph">
                  Rent-to-Own in Manitoba
                </Text>
                <div className="flex flex-col gap-2 mt-6">
                  <Link
                    target="_blank"
                    className="text-navy-dark hover:text-navy-dark"
                    href="https://www.requityhomes.com/post/rent-to-own-a-home-in-winnipeg-manitoba"
                  >
                    Rent-to-Own in Winnipeg
                  </Link>
                </div>
                <div className="flex flex-col text-center md:text-left">
                  <Text
                    fontWeight="bold"
                    useCase="paragraph"
                    className="mt-6 mb-4"
                  >
                    Rent-to-Own in Ontario
                  </Text>
                  <div className="flex flex-col gap-2">
                    <Link
                      target="_blank"
                      className="text-navy-dark hover:text-navy-dark"
                      href="https://www.requityhomes.com/post/rent-to-own-in-hamilton-ontario"
                    >
                      Rent-to-Own in Hamilton
                    </Link>
                    <Link
                      target="_blank"
                      className="text-navy-dark hover:text-navy-dark"
                      href="https://www.requityhomes.com/post/rent-to-own-a-home-in-kingston-ontario"
                    >
                      Rent-to-Own in Kingston
                    </Link>
                    <Link
                      target="_blank"
                      className="text-navy-dark hover:text-navy-dark"
                      href="https://www.requityhomes.com/post/rent-to-own-a-home-in-north-bay-ontario"
                    >
                      Rent-to-Own in North Bay
                    </Link>
                    <Link
                      target="_blank"
                      className="text-navy-dark hover:text-navy-dark"
                      href="https://www.requityhomes.com/post/rent-to-own-a-home-in-ottawa-ontario"
                    >
                      Rent-to-Own in Ottawa
                    </Link>
                    <Link
                      target="_blank"
                      className="text-navy-dark hover:text-navy-dark"
                      href="https://www.requityhomes.com/post/rent-to-own-in-oshawa-ontario"
                    >
                      Rent-to-Own in Oshawa
                    </Link>
                    <Link
                      target="_blank"
                      className="text-navy-dark hover:text-navy-dark"
                      href="https://www.requityhomes.com/post/rent-to-own-in-sault-ste-marie-ontario"
                    >
                      Rent-to-Own in Sault Ste. Marie
                    </Link>
                    <Link
                      target="_blank"
                      className="text-navy-dark hover:text-navy-dark"
                      href="https://www.requityhomes.com/post/rent-to-own-in-sudbury-ontario"
                    >
                      Rent-to-Own in Sudbury
                    </Link>
                    <Link
                      target="_blank"
                      className="text-navy-dark hover:text-navy-dark"
                      href="https://www.requityhomes.com/post/rent-to-own-in-thunder-bay-ontario"
                    >
                      Rent-to-Own in Thunder Bay
                    </Link>
                    <Link
                      target="_blank"
                      className="text-navy-dark hover:text-navy-dark"
                      href="https://www.requityhomes.com/post/rent-to-own-a-home-in-windsor-ontario"
                    >
                      Rent-to-Own in Windsor
                    </Link>
                  </div>
                </div>
              </div>
              <div className="flex flex-col text-center md:text-left">
                <Text fontWeight="bold" useCase="paragraph">
                  Rent-to-Own in Saskatchewan
                </Text>
                <div className="flex flex-col gap-2 mt-6">
                  <Link
                    target="_blank"
                    className="text-navy-dark hover:text-navy-dark"
                    href="https://www.requityhomes.com/post/rent-to-own-a-home-in-moose-jaw-saskatchewan"
                  >
                    Rent-to-Own in Moose Jaw
                  </Link>
                  <Link
                    target="_blank"
                    className="text-navy-dark hover:text-navy-dark"
                    href="https://www.requityhomes.com/post/rent-to-own-a-home-in-prince-albert-saskatchewan"
                  >
                    Rent-to-Own in Prince Albert
                  </Link>
                  <Link
                    target="_blank"
                    className="text-navy-dark hover:text-navy-dark"
                    href="https://www.requityhomes.com/post/rent-to-own-a-home-in-regina-saskatchewan"
                  >
                    Rent-to-Own in Regina
                  </Link>
                  <Link
                    target="_blank"
                    className="text-navy-dark hover:text-navy-dark"
                    href="https://www.requityhomes.com/post/rent-to-own-a-home-in-saskatoon-saskatchewan"
                  >
                    Rent-to-Own in Saskatoon
                  </Link>
                </div>

                <div className="flex flex-col text-center md:text-left mt-5">
                  <div className="bg-coral-light p-8 rounded-2xl text-center flex flex-col gap-5">
                    <Text useCase="paragraph" fontWeight="bold">
                      Find your dream home today
                    </Text>
                    <Text>
                      Start with just 2% down and get pre-qualified in minutes -
                      no impact on your credit score.
                    </Text>
                    <Link
                      target="_blank"
                      href="https://portal.requityhomes.com/auth/sign-up"
                      className="bg-coral-medium hover:bg-white text-white hover:text-coral-medium py-4 rounded-lg font-bold no-underline"
                    >
                      Pre-Qualify Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const MobileMenu = ({ menuItems }) => {
  return (
    <div className="bg-coral-light border b-t pl-left-55 pt-4 flex flex-col gap-8 md:hidden absolute top-20 bottom-0 left-0 right-0 z-40 h-5000">
      {menuItems}
    </div>
  );
};
