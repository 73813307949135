import { useState } from 'react';
/* eslint-disable @next/next/no-img-element */
import { Button, trackEvent } from '@requity-homes/component-library';
import { getCityLink } from '../../utils';
import { Cities, externalLinks } from '../../const';
import NextLink from 'next/link';
import { StickyFooter } from './sticky-footer';

export const Footer = ({ city }: { city?: string }) => {
  const [showListingCities, setShowListingCities] = useState(false);
  const [showResources, setShowResources] = useState(false);

  const handleOpenNewTab = () => {
    trackEvent('listing-get-pre-qualified-click', { city });
    window.open('https://portal.requityhomes.com/auth/sign-up', '_blank');
  };

  return (
    <footer className="bg-coral-light p-8 pt-12 pb-12">
      <div>
        <div className="flex flex-col lg:flex-row lg:justify-between">
          <div className="flex-1 flex flex-col gap-4 mb-8 lg:mb-0 items-center md:items-start">
            <a href="https://www.requityhomes.com/">
              <img
                src="https://assets-global.website-files.com/61e19be21795b3fa062d021f/624dd69a94af1b5f89ef13f5_requity-logo.svg"
                width="150"
                alt="Requity Homes logo"
              />
            </a>
            <span>
              Rent your dream home while we <br />
              help you save for a down payment.
            </span>
            <Button
              className="mt-8 text-white active:bg-coral-medium bg-coral-medium outline-none border-transparent hover:bg-white hover:text-coral-medium mx-auto md:ml-0"
              onClick={() => handleOpenNewTab()}
            >
              Get pre-qualified
            </Button>
          </div>
          <div className="flex flex-col md:flex-row flex-2 md:gap-14">
            <div className="flex flex-col gap-4">
              <div
                onClick={() => setShowListingCities(!showListingCities)}
                className={`flex justify-between items-center border-t-2 border-black py-2 md:py-0 md:border-0`}
              >
                <h2 className="font-bold">Rent-to-Own Listings in Canada</h2>
                <div className="flex justify-center items-center md:hidden">
                  {showListingCities ? (
                    <span className="text-4xl -mt-1">-</span>
                  ) : (
                    <span className="text-3xl">+</span>
                  )}
                </div>
              </div>
              <div
                className={`${
                  showListingCities ? 'flex' : 'hidden md:flex'
                } gap-4 md:gap-6`}
              >
                <ul role="list" className="flex flex-col gap-4">
                  <li>
                    <NextLink passHref href={getCityLink(Cities.Airdrie)}>
                      <a className="hover:text-coral-medium">
                        Rent-to-Own Homes in Airdrie
                      </a>
                    </NextLink>
                  </li>
                  <li>
                    <NextLink passHref href={getCityLink(Cities.Edmonton)}>
                      <a className="hover:text-coral-medium">
                        Rent-to-Own Homes in Edmonton
                      </a>
                    </NextLink>
                  </li>
                  <li>
                    <NextLink passHref href={getCityLink(Cities.GrandePrairie)}>
                      <a className="hover:text-coral-medium">
                        Rent-to-Own Homes in Grande Prairie
                      </a>
                    </NextLink>
                  </li>
                  <li>
                    <NextLink passHref href={getCityLink(Cities.Lethbridge)}>
                      <a className="hover:text-coral-medium">
                        Rent-to-Own Homes in Lethbridge
                      </a>
                    </NextLink>
                  </li>
                  <li>
                    <NextLink passHref href={getCityLink(Cities.MooseJaw)}>
                      <a className="hover:text-coral-medium">
                        Rent-to-Own Homes in Moose Jaw
                      </a>
                    </NextLink>
                  </li>
                  <li>
                    <NextLink passHref href={getCityLink(Cities.NorthBay)}>
                      <a className="hover:text-coral-medium">
                        Rent-to-Own Homes in North Bay
                      </a>
                    </NextLink>
                  </li>
                  <li>
                    <NextLink passHref href={getCityLink(Cities.PrinceAlbert)}>
                      <a className="hover:text-coral-medium">
                        Rent-to-Own Homes in Prince Albert
                      </a>
                    </NextLink>
                  </li>
                </ul>
                <ul role="list" className="flex flex-col gap-4">
                  <li>
                    <NextLink passHref href={getCityLink(Cities.RedDeer)}>
                      <a className="hover:text-coral-medium">
                        Rent-to-Own Homes in Red Deer
                      </a>
                    </NextLink>
                  </li>
                  <li>
                    <NextLink passHref href={getCityLink(Cities.Regina)}>
                      <a className="hover:text-coral-medium">
                        Rent-to-Own Homes in Regina
                      </a>
                    </NextLink>
                  </li>
                  <li>
                    <NextLink passHref href={getCityLink(Cities.Saskatoon)}>
                      <a className="hover:text-coral-medium">
                        Rent-to-Own Homes in Saskatoon
                      </a>
                    </NextLink>
                  </li>
                  <li>
                    <NextLink passHref href={getCityLink(Cities.SaultSteMarie)}>
                      <a className="hover:text-coral-medium">
                        Rent-to-Own Homes in Sault Ste. Marie
                      </a>
                    </NextLink>
                  </li>
                  <li>
                    <NextLink passHref href={getCityLink(Cities.Sudbury)}>
                      <a className="hover:text-coral-medium">
                        Rent-to-Own Homes in Sudbury
                      </a>
                    </NextLink>
                  </li>
                  <li>
                    <NextLink passHref href={getCityLink(Cities.ThunderBay)}>
                      <a className="hover:text-coral-medium">
                        Rent-to-Own Homes in Thunder Bay
                      </a>
                    </NextLink>
                  </li>
                  <li>
                    <a
                      className="hover:text-coral-medium"
                      href={externalLinks.winnipegRealtorLink}
                    >
                      Rent-to-Own Homes in Winnipeg
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <div
                onClick={() => setShowResources(!showResources)}
                className={`flex justify-between items-center border-t-2 border-black py-2 md:py-0 md:border-0`}
              >
                <h2 className="font-bold">Resources</h2>
                <div className="flex justify-center items-center md:hidden">
                  {showResources ? (
                    <span className="text-4xl -mt-1">-</span>
                  ) : (
                    <span className="text-3xl">+</span>
                  )}
                </div>
              </div>
              <ul
                role="list"
                className={`${
                  showResources ? 'flex' : 'hidden md:flex'
                } flex-col gap-4 mb-4`}
              >
                <li>
                  <a
                    className="hover:text-coral-medium"
                    href="https://www.requityhomes.com/listings"
                  >
                    Homes
                  </a>
                </li>
                <li>
                  <a
                    className="hover:text-coral-medium"
                    href="https://www.requityhomes.com/calculators"
                  >
                    Calculator
                  </a>
                </li>
                <li>
                  <a
                    className="hover:text-coral-medium"
                    href="https://www.requityhomes.com/blog"
                  >
                    Blog
                  </a>
                </li>
                <li>
                  <a
                    className="hover:text-coral-medium"
                    href="https://www.requityhomes.com/legal"
                  >
                    About Us
                  </a>
                </li>
                <li>
                  <a
                    className="hover:text-coral-medium"
                    href="https://www.requityhomes.com/careers"
                  >
                    Careers
                  </a>
                </li>
              </ul>
            </div>
            <div className="flex flex-col gap-4 border-t-2 border-black pt-4 md:py-0 md:border-0">
              <h2 className="font-bold">Questions?</h2>
              <p>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="font-bold text-coral-medium"
                  href="mailto:info@requityhomes.com"
                >
                  info@requityhomes.com
                </a>
              </p>
              <p>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="font-bold text-coral-medium"
                  href="tel:1-855-952-6492"
                >
                  1-855-952-6492
                </a>
              </p>
              <div className="flex gap-3">
                <div>
                  <a
                    href="https://ca.linkedin.com/company/requity-homes"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="https://assets-global.website-files.com/61e19be21795b3fa062d021f/65cd0cb26b9984fa80faf51a_Linkedin%20icon.svg"
                      alt="LinkedIn"
                    />
                  </a>
                </div>
                <div>
                  <a
                    href="https://www.instagram.com/requityhomes/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="https://assets-global.website-files.com/61e19be21795b3fa062d021f/65cd0cb28eee2c9455bdbd55_Instagram%20icon.svg"
                      alt="Instagram"
                    />
                  </a>
                </div>
                <div>
                  <a
                    href="https://www.facebook.com/RequityHomes/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="https://assets-global.website-files.com/61e19be21795b3fa062d021f/65cd0cb2f98efff420fad0f1_Faecbook%20icon.svg"
                      alt="Facebook"
                    />
                  </a>
                </div>
                <div>
                  <a
                    href="https://www.youtube.com/channel/UCx35PeGtkNLkqSRlt8LI1Zw"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="https://assets-global.website-files.com/61e19be21795b3fa062d021f/65cd0cb2288f80cd3f40474e_Twitter%20icon.svg"
                      alt="Twitter"
                      className="!h-full max-w-none"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-12 md:text-center">
          <div className="flex flex-col gap-1 md:flex-row mb-3 md:text-center md:justify-center md:gap-3">
            <NextLink passHref href="https://www.requityhomes.com/legal">
              <a className="underline text-sm" target="_blank">
                Terms of Service
              </a>
            </NextLink>
            <NextLink
              passHref
              href="https://www.requityhomes.com/privacy-policy"
            >
              <a className="underline text-sm" target="_blank">
                Privacy Policy
              </a>
            </NextLink>
          </div>
          <div className="text-xs mb-4">
            The trademarks REALTOR®, REALTORS®, and the REALTOR® logo are
            controlled by The Canadian Real Estate Association (CREA) and
            identify real estate professionals who are members of CREA. The
            trademarks MLS®, Multiple Listing Service® and the associated logos
            are owned by The Canadian Real Estate Association (CREA) and
            identify the quality of services provided by real estate
            professionals who are members of CREA. Used under license.
          </div>
          <div>Copyright © 2024 Requity Homes™. All rights reserved.</div>
        </div>
      </div>
      <StickyFooter></StickyFooter>
    </footer>
  );
};
